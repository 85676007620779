<template>
  <div>
    <!-- #region begin::Searcher and filters -->
    <div>
      <ProductListFilters
        :show-options-buttons="showOptionsButtons"
        :show-location-filter="showLocationFilter"
        :can-apply-global-filters="canApplyGlobalFilters"
        @on-filter="handleFilter"
        @on:click="onSendProductsEmail"
      />
    </div>
    <!-- #endregion begin::Searcher and filters -->

    <!-- #region::Slots for showing filtered products count -->
    <slot name="filteredProductsCount" />
    <!-- #endregion::Slots for showing filtered products count -->

    <!-- #region begin::Skeletons -->
    <template v-if="isLoadingProducts">
      <b-skeleton
        class="mt-1"
        animation="wave"
        width="100%"
        height="350px"
      />
      <b-skeleton
        class="mt-1"
        animation="wave"
        width="100%"
        height="350px"
      />
    </template>
    <!-- #endregion end::Skeletons -->

    <!-- #region begin::Products list -->
    <template v-else>

      <!-- #region begin::Collapsable products list -->
      <app-collapse
        accordion
        type="margin"
      >
        <ProductCollapsableItem
          v-for="(product) in products"
          :key="product.IdProduct"
          :product="product"
          :highlight-text="highlightText"
          :show-pieces-list="showPiecesList"
          :show-edit-button="showEditButton"
          :origin-product-id="originProductId"
          :show-delete-button="showDeleteButton"
          :load-circuit-products="loadCircuitProducts"
          :selectable-pieces-list="selectablePiecesList"
          :show-add-pieces-buttons="showAddPiecesButtons"
          :can-show-product-metrics="canShowProductMetrics"
          :show-delete-pieces-buttons="showDeletePiecesButtons"
          :show-add-to-circuit-button="showAddToCircuitButton"
          :show-transfer-pieces-button="showTransferPiecesButton"
          @hide-modal="$emit('hide-modal')"
          @reload-list="reloadList()"
          @transfer-pieces="transferPieces()"
        />
      </app-collapse>
      <!-- #endregion end::Collapsable products list -->

      <!-- #region begin::Alert when no brands are available -->
      <b-alert
        v-if="!isLoadingProducts && !availableProducts"
        variant="warning"
        class="mt-2"
        show
      >
        <div class="alert-body">
          <span>No se encontraron productos.</span>
        </div>
      </b-alert>
      <!-- #endregion end::Alert when no brands are available -->

    </template>
    <!-- #endregion end::Products list -->

    <!-- #region begin::Pagination & items per list -->
    <BasicPaginator
      v-if="availableProducts && !loadCircuitProducts"
      ref="basic-paginator"
      class="mt-2"
      :total-rows="totalProducts"
      :callback="handleChangePagination"
      :can-apply-global-pagination="canApplyGlobalPagination"
    />
    <!-- #endregion end::Pagination & items per list -->
  </div>
</template>

<script>
// #region Imports
import { mapActions, mapGetters } from 'vuex'
import { BAlert, BSkeleton } from 'bootstrap-vue'

import BasicPaginator from '@/components/tables/BasicPaginator.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import ProductListFilters from '@/modules/production/products/components/ProductListFilters.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  name: 'ProductList',
  components: {
    BAlert,
    BSkeleton,
    AppCollapse,
    BasicPaginator,
    ProductListFilters,
    ProductCollapsableItem: () => import('@/modules/production/products/components/ProductCollapsableItem.vue'),
  },
  props: {
    resource: {
      type: String,
      required: true,
    },
    showOptionsButtons: {
      type: Boolean,
      default: false,
    },
    showLocationFilter: {
      type: Boolean,
      default: true,
    },
    originProductId: {
      type: Number,
      default: 0,
    },
    loadCircuitProducts: {
      type: Boolean,
      default: false,
    },
    // * Pieces list
    showPiecesList: {
      type: Boolean,
      default: true,
    },
    selectablePiecesList: {
      type: Boolean,
      default: false,
    },
    // * Card buttons
    showTransferPiecesButton: {
      type: Boolean,
      default: false,
    },
    showAddToCircuitButton: {
      type: Boolean,
      default: false,
    },
    showAddPiecesButtons: {
      type: Boolean,
      default: false,
    },
    showDeletePiecesButtons: {
      type: Boolean,
      default: false,
    },
    showDeleteButton: {
      type: Boolean,
      default: false,
    },
    showEditButton: {
      type: Boolean,
      default: false,
    },
    // * highlight text by seracher
    highlightText: {
      type: Boolean,
      default: false,
    },
    checkTransfer: {
      type: Boolean,
      default: false,
    },
    /**
     * Indica si se pueden aplicar los filtros globales
     */
    canApplyGlobalFilters: {
      type: Boolean,
      default: true,
    },
    /**
     * Indica si se puede aplicar la paginación global
     */
    canApplyGlobalPagination: {
      type: Boolean,
      default: true,
    },
    /**
     * Indica si se pueden mostrar las métricas de los productos
     */
    canShowProductMetrics: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    /**
     * Rutas donde es necesario establecer el término de búsqueda por ubicación
     * con la variable de estado locationTerm
     */
    const ROUTES_FOR_LOCATION_TERM = ['new-transfer', 'transfer-details']

    return {
      products: [],
      lastPage: 0,
      totalProducts: 0,
      loadFilters: false,
      isLoadingProducts: false,

      /**
       * Local pagination
       */
      localPerPage: 10,
      localCurrentPage: 1,

      ROUTES_FOR_LOCATION_TERM,

      /**
       * Local filters
       */
      localBrand: null,
      localSearchTerm: null,
      localSubcategory: null,
      localProductType: null,
      localPendingImage: null,
      localPiecesLocation: null,
      localPriceToBeDefined: null,
    }
  },
  computed: {
    ...mapGetters({
      getRealCount: 'products/getRealCount',
      getLocationTerm: 'products/getLocationTerm',
      getCircuitProducts: 'circuits/getCircuitProducts',
      getFilteredCircuitProducts: 'circuits/getFilteredCircuitProducts',

      /**
       * Filtros globales
       */
      getFilteredBrand: 'filters/getFilteredBrand',
      getFilteredPerPage: 'filters/getFilteredPerPage',
      getFilteredSearchTerm: 'filters/getFilteredSearchTerm',
      getFilteredCurrentPage: 'filters/getFilteredCurrentPage',
      getFilteredSubcategory: 'filters/getFilteredSubcategory',
      getFilteredProductType: 'filters/getFilteredProductType',
      getFilteredPendingImage: 'filters/getFilteredPendingImage',
      getFilteredPiecesLocation: 'filters/getFilteredPiecesLocation',
      getFilteredPriceToBeDefined: 'filters/getFilteredPriceToBeDefined',
    }),
    /**
     * Término de ubicación de búsqueda del store de productos
     */
    locationTerm: {
      get() { return this.getLocationTerm },
      set(value) { this.setLocationTerm(value) },
    },
    /**
     * Cantidad de productos reales (sin paginación)
     */
    stateRealCount: {
      get() { return this.getRealCount },
    },
    /**
     * Productos del circuito
     */
    circuitProducts: {
      get() { return this.getCircuitProducts },
    },
    /**
     * Marca filtrada (filtro en store)
     */
    filteredBrand: {
      get() { return this.getFilteredBrand },
      set(value) { this.setFilteredBrand(value) },
    },
    /**
     * Indica la cantidad de elementos por página (paginado global)
     */
    filteredPerPage: {
      get() { return this.getFilteredPerPage },
      set(value) { this.setFilteredPerPage(value) },
    },
    /**
     * Término de búsqueda filtrado (filtro en store)
     */
    filteredSearchTerm: {
      get() { return this.getFilteredSearchTerm },
      set(value) { this.setFilteredSearchTerm(value) },
    },
    /**
     * Indica la página actual (paginado global)
     */
    filteredCurrentPage: {
      get() { return this.getFilteredCurrentPage },
      set(value) { this.setFilteredCurrentPage(value) },
    },
    /**
     * Subcategoría filtrada (filtro en store)
     */
    filteredSubcategory: {
      get() { return this.getFilteredSubcategory },
      set(value) { this.setFilteredSubcategory(value) },
    },
    /**
     * Tipo de producto filtrado (filtro en store)
     */
    filteredProductType: {
      get() { return this.getFilteredProductType },
      set(value) { this.setFilteredProductType(value) },
    },
    /**
     * Ubicación de piezas filtrada (filtro en store)
     */
    filteredPiecesLocation: {
      get() { return this.getFilteredPiecesLocation },
      set(value) { this.setFilteredPiecesLocation(value) },
    },
    /**
     * Indica si se deben mostrar los productos con imágenes pendientes
     */
    filteredPendingImage: {
      get() { return this.getFilteredPendingImage },
      set(value) { this.setFilteredPendingImage(value) },
    },
    /**
     * Indica si se deben mostrar los productos con precios por definir
     */
    filteredPriceToBeDefined: {
      get() { return this.getFilteredPriceToBeDefined },
      set(value) { this.setFilteredPriceToBeDefined(value) },
    },

    availableProducts() {
      return this.totalProducts > 0 || this.circuitProducts.length > 0
    },
    showEmptyPiecesProducts() {
      return this.$route.name === 'new-circuit' || this.$route.name === 'update-circuit' ? 1 : 0
    },
  },
  watch: {
    circuitProducts(products) {
      if (this.loadCircuitProducts) {
        if (this.products.length !== products.length) {
          this.products = [...products]
        }
      }
    },
  },

  /**
  * Hook que se ejecuta cuando se crea el componente
  *
  * @summary Carga la lista de productos y las listas de marcas y categorías para
  * los filtros
  */
  async created() {
    try {
      if (this.loadCircuitProducts) {
        this.products = this.getCircuitProducts
        this.totalProducts = this.products.length
      } else {
        await this.loadProductsListWithVerification()
      }
      await this.loadBrandsWithoutPagination()
      await this.loadCategoriesWithoutPagination()
    } catch (error) {
      this.showToast('Error de validación', getError(error), 'danger')
    } finally {
      this.$swal.close()
    }
  },

  /**
   * Hook que se ejecuta cuando se monta el componente
   *
   * @summary Emite el evento de que se ha montado el componente
   */
  mounted() {
    this.$emit('product-list-mounted')
  },
  methods: {
    ...mapActions({
      loadProducts: 'products/loadProducts',
      setLocationTerm: 'products/setLocationTerm',
      sendProductsEmail: 'products/sendProductsEmail',
      loadBrandsWithoutPagination: 'brands/loadBrandsWithoutPagination',
      loadCategoriesWithoutPagination: 'categories/loadCategoriesWithoutPagination',

      /**
       * Filtros globales
       */
      setFilteredBrand: 'filters/setFilteredBrand',
      setFilteredPerPage: 'filters/setFilteredPerPage',
      setFilteredSearchTerm: 'filters/setFilteredSearchTerm',
      setFilteredCurrentPage: 'filters/setFilteredCurrentPage',
      setFilteredSubcategory: 'filters/setFilteredSubcategory',
      setFilteredProductType: 'filters/setFilteredProductType',
      setFilteredPendingImage: 'filters/setFilteredPendingImage',
      setFilteredPiecesLocation: 'filters/setFilteredPiecesLocation',
      setFilteredPriceToBeDefined: 'filters/setFilteredPriceToBeDefined',
    }),

    /**
    * Evento de filtrado
    *
    * @summary Atiende el evento de filtrado del compontente filter, asignando
    * términos de búsqueda en base a los campos de selección y el buscador así como
    * establecer los filtros globales en el store
    * @param {Object} filter - Objeto con el tipo de filtro y el valor
    */
    async handleFilter(filter) {
      switch (filter.type) {
        case 'brand':
          if (this.canApplyGlobalFilters) {
            this.filteredBrand = filter.value
          } else {
            this.localBrand = filter.value
          }
          break
        case 'category':
          if (this.canApplyGlobalFilters) {
            this.filteredSubcategory = filter.value
          } else {
            this.localSubcategory = filter.value
          }
          break
        case 'type':
          if (this.canApplyGlobalFilters) {
            this.filteredProductType = filter.value
          } else {
            this.localProductType = filter.value
          }
          break
        case 'location':
          if (this.ROUTES_FOR_LOCATION_TERM.includes(this.$route.name)) {
            this.locationTerm = filter.value
          } else if (this.canApplyGlobalFilters) {
            this.filteredPiecesLocation = filter.value
          } else {
            this.localPiecesLocation = filter.value
          }
          break
        case 'pendingImage':
          if (this.canApplyGlobalFilters) {
            this.filteredPendingImage = filter.value
          } else {
            this.localPendingImage = filter.value
          }
          break
        case 'priceToBeDefined':
          if (this.canApplyGlobalFilters) {
            this.filteredPriceToBeDefined = filter.value
          } else {
            this.localPriceToBeDefined = filter.value
          }
          break
        default:
          if (this.canApplyGlobalFilters) {
            this.filteredSearchTerm = filter.value
          } else {
            this.localSearchTerm = filter.value
          }
          break
      }

      if (this.loadCircuitProducts) {
        this.products = this.getFilteredCircuitProducts(this.loadParams())
        this.totalProducts = this.products.length
      } else {
        this.loadProductsListWithVerification(true)
      }
    },

    /**
    * Carga de elementos de la lista con verificación de paginado
    *
    * @summary Carga la lista de elementos y verifica que la página actual contenga elementos
    */
    async loadProductsListWithVerification(resetList = false) {
      await this.loadListProducts(resetList)
      this.verifyContenList(resetList)
    },

    /**
    * Evento de paginación
    *
    * @summary Evento del componente de paginación. Establece la página actual y la cantidad
    * de registros por página
    * @param {number} currentPage - Página actual
    * @param {number} perPage - Elementos a mostrar por página
    */
    async handleChangePagination(currentPage, perPage) {
      if (this.canApplyGlobalPagination) {
        this.filteredPerPage = perPage
        this.filteredCurrentPage = currentPage
      } else {
        this.localPerPage = perPage
        this.localCurrentPage = currentPage
      }
      this.loadListProducts()
    },

    async reloadList() {
      await this.loadListProducts()
      this.verifyContenList()
    },

    /**
    * Verificación de contenido
    *
    * @summary Verifica que la página actual tenga registros, en caso de no tenerlos
    * tomará la última página disponible y cargará los registros
    */
    async verifyContenList(resetLists = false) {
      if (this.products.length === 0) {
        if (this.canApplyGlobalPagination) {
          this.filteredCurrentPage = this.lastPage
        } else {
          this.localCurrentPage = this.lastPage
        }

        // Verifica si hay registros disponibles en el servidor
        if (this.availableProducts) {
          // eslint-disable-next-line no-unused-expressions
          this.$refs['basic-paginator']?.resetCurrentPage(this.lastPage)
          await this.loadListProducts(resetLists)
        }
      }
    },

    /**
    * Carga la lista de productos
    *
    * @summary Carga la lista de productos en base a los parámetros de búsqueda
    */
    async loadListProducts(resetLists = false) {
      this.$swal({
        title: 'Cargando...',
        allowOutsideClick: false,
      })
      this.$swal.showLoading()
      this.isLoadingProducts = true

      const response = await this.loadProducts({ loadParams: this.loadParams(), resetLists })

      this.products = response.data.data.products.data
      this.totalProducts = response.data.data.products.total
      this.lastPage = response.data.data.products.last_page

      this.isLoadingProducts = false
      this.$swal.close()
    },

    /**
    * Parámetros de carga
    *
    * @summary Devuelve los parámetros de carga de la lista
    */
    loadParams() {
      return {
        resource: this.resource,
        exceptTerm: this.originProductId,
        qtyActive: this.showEmptyPiecesProducts,
        perPage: this.canApplyGlobalPagination ? this.filteredPerPage : this.localPerPage,
        currentPage: this.canApplyGlobalPagination ? this.filteredCurrentPage : this.localCurrentPage,

        /**
         * Verifica que se puedan cargar los filtros del store, si no es así se cargan
         * los filtros locales
         */
        brandTerm: this.canApplyGlobalFilters ? this.filteredBrand : this.localBrand,
        typeTerm: this.canApplyGlobalFilters ? this.filteredProductType : this.localProductType,
        searchTerm: this.canApplyGlobalFilters ? this.filteredSearchTerm : this.localSearchTerm,
        categoryTerm: this.canApplyGlobalFilters ? this.filteredSubcategory : this.localSubcategory,
        pendingImageTerm: this.canApplyGlobalFilters ? this.filteredPendingImage : this.localPendingImage,
        priceToBeDefinedTerm: this.canApplyGlobalFilters ? this.filteredPriceToBeDefined : this.localPriceToBeDefined,

        /**
         * locationTerm se puede establecer en cualquier componente que no
         * tenga que ser especificamente un filtro, esto permite poder filtrar
         * productos por cierta ubicación
         * Actualmente utilizado en el módulo de traspasos
         */
        locationTerm: this.canApplyGlobalFilters
          ? (this.locationTerm || this.filteredPiecesLocation)
          : (this.locationTerm || this.localPiecesLocation),

        // Valida las piezas en traspasos
        checkTransfer: this.checkTransfer ? 1 : 0,
      }
    },

    /**
    * Parámetros de descarga de documento CSV
    *
    * @summary Crea un objecto con los parámetros de descarga de documento CSV
    * @return {Object} Objeto con los parámetros de descarga de documento CSV
    */
    downloadCSVParams() {
      return {
        brandTerm: this.canApplyGlobalFilters ? this.filteredBrand : this.localBrand,
        typeTerm: this.canApplyGlobalFilters ? this.filteredProductType : this.localProductType,
        categoryTerm: this.canApplyGlobalFilters ? this.filteredSubcategory : this.localSubcategory,
        locationTerm: this.canApplyGlobalFilters
          ? (this.locationTerm || this.filteredPiecesLocation)
          : (this.locationTerm || this.localPiecesLocation),
      }
    },

    /**
    * Evento de transferencia de piezas
    *
    * @summary Evento lanzada cuando se trasnfieren peizas. Es escuchado por
    * el Modal de transferencia de piezas
    */
    transferPieces() {
      this.$emit('transfer-pieces')
    },

    /**
    * Descarga de archivo CSV
    *
    * @summary Descarga el archivo CSV de productos con los parámetros necesarios
    * tomados de los filtros
    */
    async onSendProductsEmail() {
      await this.sendProductsEmail(`${process.env.VUE_APP_API_URL}/products/reporter?brand=${this.filteredBrand}&category=${this.filteredSubcategory}&tipo=${this.filteredProductType}&location=${this.filteredPiecesLocation}`)
      this.showToast(
        'Correo enviado',
        'El correo electrónico ha sido enviado correctamente.',
        'success',
      )
    },

    /**
    * Muestra un mensaje de tipo toast
    *
    * @param {String} title - Título del mensaje
    * @param {String} text - Contenido del mensaje
    * @param {String} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
